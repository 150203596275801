import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAxios, usePrevious } from './index';
import { SET_PROVINCE } from '@volta-redux/extra-constants/province';
import { SET_COLORS } from '@volta-redux/extra-constants/color';
import {
  SET_CITIES,
  SET_CITIES_BY_PROVINCE,
} from '@volta-redux/extra-constants/city';
import { SET_PARAMETER } from '@volta-redux/extra-constants/system-parameter';
import { SET_SESSION } from '@volta-redux/extra-constants/auth';

export const useProvinces = () => {
  const [graphqlProvince, provinceLoading, provinceStatus, provinceResponse] =
    useAxios();
  const provinces = useSelector((state) => state.province.data);
  const [provincesData, setProvincesData] = useState([]);
  const prevProvinceStatus = usePrevious(provinceStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (provinces.length === 0) {
      graphqlProvince({
        id: 'getProvinces',
      });
      return;
    }
    setProvincesData(provinces);
  }, []);

  useEffect(() => {
    if (
      prevProvinceStatus !== undefined &&
      prevProvinceStatus !== provinceStatus
    ) {
      switch (provinceStatus) {
        case 1:
          dispatch({
            type: SET_PROVINCE,
            payload: provinceResponse.data.provinces.nodes,
          });
          setProvincesData(provinceResponse.data.provinces.nodes);
          break;
        default:
      }
    }
  }, [prevProvinceStatus, provinceStatus]);

  return [provincesData, provinceLoading, provinceStatus];
};

export const useCity = (provinceIdProps = null) => {
  const [graphqlcity, cityLoading, cityStatus, cityResponse] = useAxios();
  const cities = useSelector((state) => state.city.data);
  const citiesByProvince = useSelector((state) => state.city.dataByProvince);
  const [citiesData, setCityData] = useState([]);
  const [provinceId, setProvinceId] = useState(provinceIdProps);
  const prevCityStatus = usePrevious(cityStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (provinceId === null) {
      if (cities.length === 0) {
        graphqlcity({
          id: 'getCities',
          var: {
            cityCondition: {
              provinceId: 1,
            },
          },
        });
        return;
      }
      setCityData(cities);
    } else {
      provinceFetch(provinceId);
    }
  }, []);

  useEffect(() => {
    if (prevCityStatus !== undefined && prevCityStatus !== cityStatus) {
      switch (cityStatus) {
        case 1:
          if (provinceId === null) {
            dispatch({
              type: SET_CITIES,
              payload: cityResponse.data.cities.nodes,
            });
          } else {
            dispatch({
              type: SET_CITIES_BY_PROVINCE,
              payload: { provinceId, data: cityResponse.data.cities.nodes },
            });
          }
          setCityData(cityResponse.data.cities.nodes);
          break;
        default:
      }
    }
  }, [prevCityStatus, cityStatus]);

  function provinceFetch(province) {
    const c = citiesByProvince[province];
    if (!c) {
      graphqlcity({
        id: 'getCities',
        var: {
          cityCondition: {
            provinceId: Number(province),
          },
        },
      });
      return;
    }
    setCityData(c);
  }

  function onProvinceChange(province) {
    provinceFetch(province);
    setProvinceId(province);
  }

  return [onProvinceChange, citiesData, cityLoading, cityStatus];
};

export const useColors = () => {
  const [graphqlColors, colorLoading, colorStatus, colorResponse] = useAxios();
  const colors = useSelector((state) => state.color.data);
  const [colorData, setcolorsData] = useState([]);
  const prevcolorStatus = usePrevious(colorStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (colors.length === 0) {
      graphqlColors({
        id: 'getColors',
        var: {
          filter: {
            isActive: {
              equalTo: true,
            },
          },
        },
      });
      return;
    }
    setcolorsData(colors);
  }, []);

  useEffect(() => {
    if (prevcolorStatus !== undefined && prevcolorStatus !== colorStatus) {
      switch (colorStatus) {
        case 1:
          dispatch({
            type: SET_COLORS,
            payload: colorResponse.data.colors.nodes,
          });
          setcolorsData(colorResponse.data.colors.nodes);
          break;
        default:
      }
    }
  }, [prevcolorStatus, colorStatus]);

  return [colorData, colorLoading, colorStatus];
};

export const useLoggedUser = () => {
  const userData = useSelector((state) => state.auth.session.user);
  let isDealerAdmin = false;
  if (userData) {
    isDealerAdmin =
      userData.role_user_id === parseInt(process.env.REACT_APP_DEALER_ROLE_ID);
  }
  return { userData, isDealerAdmin };
};

export const useLoggedUserGroup = () => {
  const user = useSelector((state) => state.auth.session.user);
  const userGroup = useSelector((state) => state.auth.session.userGroup);
  const [graphql, loading, status, response] = useAxios();
  const dispatch = useDispatch();
  const prevStatus = usePrevious(status);

  useEffect(() => {
    if (!userGroup && user) {
      graphql({
        id: 'getUserGroupDetail',
        var: {
          id: user.user_group_id,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (prevStatus !== undefined && prevStatus !== status) {
      switch (status) {
        case 1:
          dispatch({
            type: SET_SESSION,
            payload: {
              key: 'userGroup',
              data: response.data.userGroup,
            },
          });
          break;

        default:
      }
    }
  }, [prevStatus, status]);

  return [userGroup || {}, status, loading];
};

export const useSystemParameter = () => {
  const [graphql, apiLoading, apiStatus, apiResponse] = useAxios();
  const listParameter = useSelector((state) => state.paramater.listParameters);
  const prevApiStatus = usePrevious(apiStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Array.isArray(listParameter) && listParameter.length === 0) {
      graphql({ id: 'getParameters' });
    }
  }, []);

  useEffect(() => {
    if (prevApiStatus !== undefined && apiStatus !== prevApiStatus) {
      switch (apiStatus) {
        case 1:
          dispatch({
            type: SET_PARAMETER,
            payload: apiResponse.data.parameters.nodes,
          });
          break;
      }
    }
  }, [prevApiStatus, apiStatus]);

  return [listParameter, apiLoading, graphql, apiStatus, apiResponse];
};
